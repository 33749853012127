<template>

    <div class="overflow-hidden flex flex-col py-4">

        <loader :loading="loading" />

        <div class="text-font-gray text-lg text-center font-semibold mb-2 mt-auto">Sales Estimated</div>

        <div class="text-center text-4xl font-semibold mb-2">
            {{ sales_estimated.won_with_next_to_win_with_estimated_amount | reduceBigNumbers(2) }} €
        </div>

        <div v-if="sales_estimated" class="flex-1 overflow-hidden mb-auto">
            <chart-gauge :data="[sales_estimated.won_with_next_to_win_with_estimated_objective]" color="#FFD351">
                <div class="text-font-gray text-sm">vs Obj.</div>
                <div class="text-xl font-semibold">
                    <span v-if="sales.won_with_next_to_win_with_estimated_objective > 0">+</span>
                    {{ sales_estimated.won_with_next_to_win_with_estimated_objective | numberFormat(2) }}%
                </div>
            </chart-gauge>
        </div>
        
    </div>

</template>

<script>

import { state } from '@/store';

export default {
    
    data() {
        return {
            sales_estimated: false,
            loading: false
        }
    },
    methods: {
        load() {
            this.loading = true;
            this.axios.get('pipeline/sales',{params:this.params}).then(response => {
                this.loading = false;
                this.sales_estimated = response.data.data
            })
        }
    },
    computed: {
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{sectorAmbiciona: this.$route.params.catId}}
            }
            return params
        },
        
    },
    watch: {
        params() { this.load() }
    },
    mounted() { this.load() }
}

</script>